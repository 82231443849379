import Echo from "laravel-echo";
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";

const setupEcho = () => {
  // Gep Pusher lib
  window.Pusher = require("pusher-js");
  // Initiate Echo

  window.Echo = new Echo({
    broadcaster: process.env.VUE_APP_PUSHER_APP_BROADCASTER,
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: false,
    authorizer: channel => {
      return {
        authorize: (socketId, callback) => {
          httpServiceAuth
            .post(
              apiEndpoints.ws.auth,
              {
                socket_id: socketId,
                channel_name: channel.name
              },
              {
                headers: { Subdomain: getSubdomain() }
              }
            )
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
        }
      };
    }
  });
};

/**
 * Create newly created channel becouse params used to build channel name may not be available later
 *  (in beforeUnmount for example) so we can easily unsubscribe or append new listener.
 * Maybe we could pass array of events with listeners here. SOmething like:
 * this.subscribe('declaration-resource', [1], {'declaration.created':createCallback, 'declaration.deleted':deleteCallback}
 *
 * Channel name format example: 'declaration-resource.{subdomain}.{resourceId}'.
 * Event name must always start with `.` since we dont use our backend namespace or event class name.
 */
export function subscribe(
  channelName,
  params,
  eventName,
  callback,
  isPublic = false
) {
  if (String(window.location.href).includes("localhost") || !getSubdomain()) {
    return;
  }

  if (window.Echo === undefined || window.Eco === null) {
    setupEcho();
  }
  let channel = isPublic
    ? window.Echo.channel(`${channelName}.${params.join(".")}`)
    : window.Echo.private(
        `${channelName}.${getSubdomain()}${
          params.length ? "." : ""
        }${params.join(".")}`
      );
  channel.listen(`.${eventName}`, e => {
    callback(e);
  });
  return channel;
}

// This function receive instance of Channel
export function unsubscribe(channel) {
  if (String(window.location.href).includes("localhost") || !getSubdomain()) {
    return;
  }
  window.Echo.leave(channel.name);
}

// This function receive instance of Channel
export function unsubscribeAll(channels) {
  if (String(window.location.href).includes("localhost") || !getSubdomain()) {
    return;
  }
  channels.forEach(channel => {
    window.Echo.leave(channel.name);
  });
}
